<template>
    <div>
        <Header/>
        <div class="v_content">
            <div class="justify-content-md-center" style="max-width: 150vh;margin: auto;">
                <MapView :stationdatas="stations"/>
                <b-row class="map_bar">
                    <b-col>
                        {{$t('statusbar.verygood')}}
                    </b-col>
                    <b-col>
                        {{$t('statusbar.good')}}
                    </b-col>
                    <b-col>
                        ปานกลาง
                    </b-col>
                    <b-col>
                        เริ่มมีผลกระทบ
                    </b-col>
                    <b-col>
                        มีผบกระทบต่อสุขภาพ
                    </b-col>
                </b-row>
                <b-row style="padding-top:10px;padding-bottom:40px;padding-left:10px;padding-right:10px;">
                    <b-col style="padding:0px;">
                        <div style="height:20px;background: #3BCCFF;border-top-left-radius: 10px;border-bottom-left-radius: 10px;"></div>
                    </b-col>
                    <b-col style="padding:0px;">
                        <div style="height:20px;background: #92D050;"></div>
                    </b-col>
                    <b-col style="padding:0px;">
                        <div style="height:20px;background: #FFFF00;"></div>
                    </b-col>
                    <b-col style="padding:0px;">
                        <div style="height:20px;background: #FFA200;"></div>
                    </b-col>
                    <b-col style="padding:0px;">
                        <div style="height:20px;background: #F04646;border-top-right-radius: 10px;border-bottom-right-radius: 10px;"></div>
                    </b-col>
                </b-row>
                <div style="padding-top:20px;">

                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import MapView from '../containers/Map'
import axios from 'axios'

export default {
    name: 'Map',
    components : {
        Header,
        Footer,
        MapView
    },
    data () {
        return {
            stations:[],
        }
    },
    created () {
        axios.get('http://air4thai.com/forweb/getAQI_JSON.php',{}).then((response) => {
            //console.log('res',response)
            this.stations = response.data.stations
        }).catch(err => {
            this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                title: 'Error!',
                text: err,
                icon: 'error'
            })
        })
    }
}
</script>

<style lang="scss" scoped>
.map_bar{
    text-align: center;
    margin-top:30px;
    font-size: max(1.2vw, 12px);
}
</style>